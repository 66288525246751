import React from 'react';

import styled from 'styled-components';

import { Paragraph, Text } from 'app/components/common/Typography';
import Button from 'app/components/common/Button';
import ImageIcon from 'app/components/icons/Image';

import Section from './Section';
import EmptyBox from './EmptyBox';
import Avatar from './Avatar';
import { ShortDetails, getHasDetails } from './Details';
import UploadImage from './UploadImage';
import EditButton from './EditButton';

import useAction from 'utils/useAction';

import { actions as loginActions } from 'app/containers/Login/slice';
import { actions as profileActions } from '../slice';

import config from 'config';

const DEFAULT_SPLASH_URL = config.IS_PRODUCTION
  ? '/static/media/splash/zenu12.png'
  : 'https://dev.zenu.me/static/media/splash/zenu12.png';

export default function Header({ profile, isEditMode, onEdit }) {
  if (!profile) return null;

  const [isEditSplash, setIsEditSplash] = React.useState(false);

  const setUser = useAction(loginActions.setUser);
  const setProfile = useAction(profileActions.setProfile);

  const handleOpenSplash = () => setIsEditSplash(true);
  const handleCloseSplash = () => setIsEditSplash(false);

  const handleChangePicture = splash => {
    setUser({ splash });
    setProfile({ splash, profile: {} });
  };

  const hasInfo = getHasHeader(profile) || !isEditMode;

  const infoContent = hasInfo ? (
    <>
      <Paragraph level="small" mb="19px">
        {profile.headline}
      </Paragraph>
      <ShortDetails profile={profile} />
    </>
  ) : (
    <EmptyBox mb="14px" onClick={onEdit}>
      Welcome to your Zenu profile!{' '}
      <span style={{ textDecoration: 'underline' }}>
        Please tell us a bit more about yourself.
      </span>
    </EmptyBox>
  );

  return (
    <Section noPad>
      <Splash $src={profile.splash || DEFAULT_SPLASH_URL}>
        {isEditMode && (
          <EditSplashButton
            size="small"
            iconLeft={<ImageIcon />}
            onClick={handleOpenSplash}
          >
            UPLOAD IMAGE
          </EditSplashButton>
        )}
        <UploadImage
          shape="rect"
          aspect={3.355}
          visible={isEditSplash}
          action="/users/splash"
          onClose={handleCloseSplash}
          onChange={handleChangePicture}
        />
      </Splash>
      <Info>
        <EditButton isEditMode={isEditMode && hasInfo} onEdit={onEdit} />
        <AvatarRoot>
          <Avatar src={profile.avatar} isEditMode={isEditMode} />
        </AvatarRoot>
        <Text level="small" mb="12px">
          <Text
            level="body"
            fontWeight={700}
            color="#323a3f"
          >{`${profile.firstName} ${profile.lastName}`}</Text>
          {profile.location && ` from ${profile.location}`}
        </Text>
        {infoContent}
      </Info>
    </Section>
  );
}

export function getHasHeader(profile) {
  return Boolean(profile?.headline) || getHasDetails(profile);
}

const Splash = styled.div<{ $src: string }>`
  height: 220px;
  width: 100%;
  background: no-repeat center/cover url(${props => props.$src});
`;

const Info = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 20px 48px 24px 170px;
  min-height: 140px;
`;

const AvatarRoot = styled.div`
  position: absolute;
  left: 20px;
  top: -20px;
  padding: 10px;
  border-radius: 50%;

  background-color: white;
`;

const EditSplashButton = styled(Button)`
  float: right;
  margin: 16px;
  border-radius: 8px;

  box-shadow: 0px 6px 12px rgba(34, 38, 77, 0.08),
    0px 1px 3px rgba(34, 38, 77, 0.06), 0px 0px 1px rgba(34, 38, 77, 0.25);

  > span {
    font-size: 10px;
    margin: 10px;
  }

  > svg {
    margin-left: 10px;
  }
`;
